export const useMediaCardSize = () => {

  const main = ref('w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6 2xl:w-1/8')
  const sidebar = ref('w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/2')

  return {
    main,
    sidebar,
  }

}

export const useDoubleCardSize = ref('w-full sm:w-2/3 md:w-1/2 lg:w-2/5 xl:w-1/3 2xl:w-1/4')